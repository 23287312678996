import React from 'react';
import { H1, size } from '@everlywell/leaves';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <div style={{ margin: `${size.xl2}px` }}>
      <H1>PAGE NOT FOUND 2</H1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
